import React from "react";
import PropTypes from "prop-types";

import { Image } from "@sparefoot/react-commons";
import { Section, Container, Row, Column } from "components/core/Page";
import { H3, Paragraph } from "components/core/Typography";

import SearchSVG from "assets/images/search.svg";
import ComparisonSVG from "assets/images/comparison.svg";
import ReserveSVG from "assets/images/reserve.svg";

import "./ThreeSimpleStepsSection.scss";

const IconCard = ({ iconSrc, iconAlt, subtitle, title, body }) => (
	<div className="icon-card">
		<div className="icon-image">
			<Image
				src={iconSrc}
				alt={iconAlt}
			/>
		</div>
		<Paragraph
			size="small"
			last
			className="icon-subtitle"
			weight="bold"
			color="gray"
		>
			{subtitle}
		</Paragraph>
		<H3>{title}</H3>
		<Paragraph last>{body}</Paragraph>
	</div>
);

IconCard.propTypes = {
	iconSrc: PropTypes.string.isRequired,
	iconAlt: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired
};

const cardsContent = [
	{
		iconSrc: SearchSVG,
		iconAlt: "Search icon",
		subtitle: "Step 1",
		title: "Search for Facilities",
		body: "Search by city or zip code to see available storage units in your area."
	},
	{
		iconSrc: ComparisonSVG,
		iconAlt: "Comparison icon",
		subtitle: "Step 2",
		title: "Compare Storage Facilities",
		body: "Find the best storage facility for you based on location, size, amenities, and price."
	},
	{
		iconSrc: ReserveSVG,
		iconAlt: "Reserve icon",
		subtitle: "Step 3",
		title: "Reserve a Storage Unit",
		body: "Reserve online or by phone for FREE with no cancellation fees."
	}
];

const ThreeSimpleStepsSection = () => (
	<Section
		flush
		className="three-simple-steps"
	>
		<Container>
			<H3
				className="section-title"
				size="xxlarge"
				mediumSize="large"
			>
				Reserve a Storage Unit in Three Simple Steps
			</H3>
			<Row gutter="large">
				{cardsContent.map((card) => (
					<Column
						large={4}
						medium={4}
						small={12}
						key={card.title}
					>
						<IconCard {...card} />
					</Column>
				))}
			</Row>
		</Container>
	</Section>
);

export default ThreeSimpleStepsSection;
